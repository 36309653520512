import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import SanitizedHTML from 'react-sanitized-html';

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import ContactSidebar from "@components/modules/contact-sidebar/";
import SocialShare from "@components/social-share/";

import ReactMarkdown from "react-markdown";
import ReactHtmlParser from 'react-html-parser';

import "./assets/_index.scss";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const ArticleText = (props) => {
    let textAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: textAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.fromTo(textAnim, {
            opacity: 0,
            y: 70,
        }, {
            opacity: 1,
            y: 0,
            duration: 1.5
        })
    }, [])

    console.log("Date_Description", props.Date_Description)

    const sidebarData = {
        hideModuleBg: true,
        sidebarVideo: {
            videoask: true,
            videoId:"https://www.videoask.com/fkhi9ib3m",
            videoTitle: "Employers are 4x more likely to interview candidates who submit a video application!"
        }
    }

    return (
        <section className={`section section-article-text${props.sectionClass}`}>
            <Container>
                <Row>
                    <Col xs={12} xl={7} id="sidebar-end-scroll" ref={el => textAnim = el}>
                        {/* <SanitizedHTML
                            allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
                            'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
                            'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre']}
                            html={props.text}
                            className="article-text"
                        /> */}
                        {props.Title &&
                            <h2 className="article-text-title article-text-title-heading">{props.Title}</h2>
                        }

                        {props.Sub_Title &&
                            <h3 className="article-text-sub-title">{props.Sub_Title}</h3>
                        }

                        <ReactMarkdown source={props.text} className="article-text article-text-paragraph" escapeHtml={false} />
                        {
                            props.showShare && (
                                <SocialShare
                                    showAsList={props.showShare}
                                    hideShareIcon={true}
                                    btnLabel="Share this Post"
                                    pageLocation={props.pageLocation}
                                    pageTitle={props.pageTitle}
                                    sharePage={props.sharePage}
                                />
                            )
                        }


                        {props.Date_Title &&
                            <h4 className="article-text-date-title">{props.Date_Title}</h4>
                        }

                        {props.Date_Description &&
                            <p className="article-text-date-description">{ReactHtmlParser(props.Date_Description)}</p>
                        }

                    </Col>

                    {/* <ContactSidebar
                        moduleClass=" module-contact-sidebar col-xs-12 offset-xl-1 col-xl-4 mt-40 mt-md-60 mt-xl-6"
                        btnClass="btn btn-primary btn-block btn-primary-no-shadow mb-20"
                        btnClass2="btn btn-outline-secondary btn-block"
                        membersData={props.membersData}
                        sidebarData={props.sidebarData}
                    /> */}

                    <ContactSidebar
                        sidebarData={sidebarData}
                    />
                </Row>
            </Container>
        </section>
    )
}

// Check all the prop types
ArticleText.propTypes = {
    sectionClass: PropTypes.string,
    Title: PropTypes.string,
    text: PropTypes.string
};

// Specifies the default values for props:
ArticleText.defaultProps = {
    sectionClass: ' pt-34 pb-40 pt-md-54 pb-md-60 pt-xl-91 pb-xl-100',
    Title: null,
    text: '<p>So, you’re sitting at your desk today and you’re thinking that you shouldn’t be. You are doing an amazing job, but you are underappreciated, not being offered the right type of training to progress and develop, or just flat out being underpaid.</p><p>The last few months here at Crux have really highlighted to us how some companies are paying really low numbers compared to others, but the people there have been there for so long that they don’t know any different! <a href="#">apply@cruxcareers.com</a></p><h2>Preparing Your CV</h2><p>So, you’re sitting at your desk today and you’re thinking that you shouldn’t be. You are doing an amazing job, but you are underappreciated</p><blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eget condimentum arcu, non tincidunt arcu. Ut ac fringilla diam, mattis finibus mi. Sed feugiat interdum eros.</blockquote>'
};

export default ArticleText;