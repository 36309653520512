import React, { Fragment } from "react";

import VideoTextModule from "@components/modules/video-text/video-text";
import VideoTeamTestimonials from "@components/modules/video-team-testimonials/video-team-testimonials";
import VideoTeamTestimonialsEmployer from "@components/modules/video-team-testimonials/video-team-testimonials-employer";
import VideoTeamTestimonialsCandidate from "@components/modules/video-team-testimonials/video-team-testimonials-candidate";
import SubscriptionTable from "@components/subscription-table/";
import ImageBoxText from "@components/modules/image-box-text/";
import ImageBoxTextConsulting from "@components/modules/image-box-text-consulting/";
import ImageTextModule from "@components/modules/image-text/";
import FormSubscribe from "@components/forms/subscribe-form/subscribe-form";
import Introtext from "@components/introtext/";
import OurPeople from "@components/our-people/";
import FindJobForm from "@components/find-job/find-job-role";
import LatestJobsModule from "@components/modules/latest-jobs/latest-jobs";
import SendCv from "@components/forms/send-cv/";
import ContactUs from "@components/forms/contact/";
//import GoogleReviews from "@components/google-reviews/GoogleReviews";
//import GoogleLatestReviews from "@components/google-reviews/LatestReviews";
import MoreAgencyPosts from "@components/modules/more-agency-posts/";

class ModuleRender extends React.Component {
    moduleSwitch(param) {
        const Modules = param;
        switch(Modules.component) {
            case "FindJobForm":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <FindJobForm
                            Title={Modules.moduleName}
                            sectionClass={Modules.sectionClass}
                            moduleType={Modules.moduleType}
                            
                        />
                    )
                } else {
                    return (
                        <FindJobForm
                            Title={Modules.moduleName}
                            sectionClass={Modules.sectionClass}
                            moduleType={Modules.moduleType}

                        />
                    )
                }
            case "VideoTextModule":
                const videoModule = Modules.moduleType[0];
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <VideoTextModule
                            Title={videoModule.Title}
                            Text={videoModule.Text}
                            videoaskUrl={videoModule.Video ? videoModule.Video.videoId : null}
                            videoask={videoModule.Video?.videoask ? videoModule.Video?.videoask : ''}
                            imgUrl={videoModule.Image ? videoModule.Image.childImageSharp.fluid : null}
                            ButtonData={videoModule.Buttons}
                            moduleData={videoModule}
                            showVideo={videoModule.Video ? videoModule.Video.showVideo : null}
                            videoId={videoModule.Video ? videoModule.Video.videoId : null}
                            videoChannel={videoModule.Video ? videoModule.Video.videoChannel : null}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <VideoTextModule
                            Title={videoModule.Title}
                            Text={videoModule.Text}
                            videoaskUrl={videoModule.Video ? videoModule.Video.videoId : null}
                            videoask={videoModule.Video?.videoask ? videoModule.Video?.videoask : ''}
                            imgUrl={videoModule.Image ? videoModule.Image.childImageSharp.fluid : null}
                            ButtonData={videoModule.Buttons}
                            moduleData={videoModule}
                            showVideo={videoModule.Video ? videoModule.Video.showVideo : false}
                            videoId={videoModule.Video ?  videoModule.Video.videoId : null}
                            videoChannel={videoModule.Video ? videoModule.Video.videoChannel : null}
                        />
                    )
                }
            
            case "ImageBoxText":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "") && (Modules.sectionClass !== " crux-consulting-boxes")) {
                    return (
                        <ImageBoxText
                            moduleData={Modules.moduleType}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                }
                else if(Modules.sectionClass === " crux-consulting-boxes") {
                    return (
                        <ImageBoxTextConsulting
                        moduleData={Modules.moduleType}
                        sectionClass={Modules.sectionClass}
                    />
                    )
                }
                else {
                    return (
                        <ImageBoxText
                            moduleData={Modules.moduleType}
                        />
                    )
                }
            case "ImageTextModule":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <ImageTextModule
                            Title={Modules.moduleName}
                            moduleData={Modules.moduleType}
                            imgUrl={Modules.moduleType[0].Image ? Modules.moduleType[0].Image.childImageSharp.fluid : null}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <ImageTextModule
                            Title={Modules.moduleName}
                            moduleData={Modules.moduleType}
                            imgUrl={Modules.moduleType[0].Image ? Modules.moduleType[0].Image.childImageSharp.fluid : null}
                        />
                    )
                }
            case "SubscriptionPlan":
                if (Modules.sectionClass !== null) {
                    return (
                        <SubscriptionTable
                            Title={Modules.moduleName}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <SubscriptionTable
                            Title={Modules.moduleName}
                        />
                    )
                }
            case "NewsletterSubscription":
                if (Modules.sectionClass !== null) {
                    return (
                        <FormSubscribe
                            Title={Modules.moduleName}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <FormSubscribe
                            Title={Modules.moduleName}
                        />
                    )
                }
            case "VideoTeamTestimonial":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <VideoTeamTestimonials
                            Title={Modules.moduleName}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <VideoTeamTestimonials
                            Title={Modules.moduleName}
                        />
                    )
                }
            case "VideoTeamTestimonialCandidate":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <VideoTeamTestimonialsCandidate
                            Title={Modules.moduleName}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <VideoTeamTestimonialsCandidate
                            Title={Modules.moduleName}
                        />
                    )
                }
            case "VideoTeamTestimonialEmployer":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <VideoTeamTestimonialsEmployer
                            Title={Modules.moduleName}
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <VideoTeamTestimonialsEmployer
                            Title={Modules.moduleName}
                        />
                    )
                }
            case "FeaturedJobs":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        // <LatestJobsModule
                        //     Title={Modules.moduleName}
                        //     sectionClass={Modules.sectionClass}
                        // />
                        <></>
                    )
                } else {
                    return (
                        // <LatestJobsModule
                        //     Title={Modules.moduleName}
                        // />
                        <></>
                    )
                }
            case "JustText":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "") && (Modules.sectionClass !== " show-crux-mentors")) {
                    return (
                        <Introtext
                            sectionClass={Modules.sectionClass}
                            Title={Modules.moduleName}
                            Text={Modules.moduleType[0].Text}
                            moduleClass={Modules.moduleType[0].moduleClass}
                        />
                    )
                } else if(Modules.sectionClass === " show-crux-mentors") {
                    return (
                        <MoreAgencyPosts
                        moduleData={Modules.moduleType}
                        sectionClass={Modules.sectionClass}
                    />
                    )
                } else {
                    return (
                        <Introtext
                            Title={Modules.moduleName}
                            Text={Modules.moduleType[0].Text}
                            moduleClass={Modules.moduleType[0].moduleClass}
                        />
                    )
                }
            case "AllTeamMembersHover":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <OurPeople
                            sectionClass={Modules.sectionClass}
                        />
                    )
                } else {
                    return (
                        <OurPeople />
                    )
                }
            case "SendUsYourCVForm":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {    
                    return (
                        <SendCv
                            sectionClass={Modules.sectionClass}
                            formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
                            title={Modules.moduleType[0].Title}
                            intro={Modules.moduleType[0].Text}
                        />
                    )
                } else {
                    return (
                        <SendCv
                            sectionClass=" grey-bg pt-34 pb-36 pt-md-52 pb-md-60 pt-xl-72 pb-xl-94"
                            formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
                            title={Modules.moduleType[0].Title}
                            intro={Modules.moduleType[0].Text}
                        />
                    )
                }
            case "ContactUsForm":
                if ((Modules.sectionClass !== null) && (Modules.sectionClass !== "")) {
                    return (
                        <ContactUs
                            sectionClass={Modules.sectionClass}
                            formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
                            showTitle={Modules.moduleType[0].showTitle}
                            title={Modules.moduleType[0].Title}
                            intro={Modules.moduleType[0].Text}
                        />
                    )
                } else {
                    return (
                        <ContactUs
                            sectionClass=" grey-bg pt-34 pb-36 pt-md-52 pb-md-60 pt-xl-72 pb-xl-94"
                            showTitle={Modules.moduleType[0].showTitle}
                            formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
                            title={Modules.moduleType[0].Title}
                            intro={Modules.moduleType[0].Text}
                        />
                    )
                }
            default:
                return null;
        }
    }
    render() {
        const modules = this.props.moduleData;
        return ( 
            <Fragment>
                {modules.modules && modules.modules.map((Modules, i) => {
                    return (
                        <Fragment key={Modules.id}>
                            {this.moduleSwitch(Modules)}
                        </Fragment>
                    )
                })}
            </Fragment>
        )
    }
}

export default ModuleRender;