import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import SanitizedHTML from 'react-sanitized-html';

const TabPane = (props) => {
    return (
        <Tab.Pane eventKey={props.eventKey}>
            <Row>
                {props.tabData.map((item, index) => (
                    <div key={index} className={props.tabContentClass}>
                        <div className={props.tabItemClass}>
                            <h4 className="h5 font-weight-bold mb-14 mb-md-5 mb-xl-26 subscription-table__title">{item.title}</h4>
                            {item.text &&
                                <SanitizedHTML html={item.text} className="mb-21 mb-md-31" />
                            }
                            {item.btnLink &&
                                <Link className={item.btnClass} to={item.btnLink}>{item.btnLabel}</Link>
                            }
                            {item.disclaimer &&
                                <p className="subscription-table__disclaimer mt-24 mt-xl-34">{item.disclaimer}</p>
                            }
                        </div>
                    </div>
                ))}
            </Row>
        </Tab.Pane>
    );
}

// Check all the prop types
TabPane.propTypes = {
    tabData: PropTypes.array
};

export default TabPane;
