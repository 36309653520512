import React from 'react';
import Nav from 'react-bootstrap/Nav'

const Pills = (props) => {
    return (
        <Nav.Item>
            <Nav.Link eventKey={props.eventKey}>{props.pillTitle}</Nav.Link>
        </Nav.Item>
    );
}

export default Pills;
