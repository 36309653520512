
import React from 'react';
import { Link } from 'gatsby'

const OurPeopleCardName = (props) => {
    return (
        <>
            {props.bioLink && <Link to={props.bioLink} className="our-people-card__text__link__title"><h2 className={props.titleClass}>{props.name}</h2></Link>}
            {!props.bioLink && <h2 className={props.titleClass}>{props.name}</h2>}
        </>
    );
}

export default OurPeopleCardName;