import React from 'react';
import PropTypes from 'prop-types';

import ContentHeaderImageBtns from "@components/modules/content-header-image/_content-header-image-btns";

function createHTMLMarkup(props) {
    return {__html: (props.html)};
}
  
function HtmlText(props) {
    return <div dangerouslySetInnerHTML={createHTMLMarkup(props)} />;
}

const TextBox = (props) => {
    //console.log("gggggg", props.item.Text)

    var text = `<p>${props.item.Text.replace(/\n/g, '<br />')}</p>`; 
    return (
        
        <div className={`section-image-box-text__text ${props.boxClass}`}>
            <h2 className={props.titleClass}>{props.item.Title}</h2>
            
            {/* <div dangerouslySetInnerHTML={createHTMLMarkup(text)} /> */}

            <HtmlText html={text} />

            <ContentHeaderImageBtns
                btnContainerClass=""
                btnClass={props.btnClass}
                ButtonData={props.item.Buttons}
            />
        </div>
    );
}

// Check all the prop types
TextBox.propTypes = {
    boxClass: PropTypes.string,
    titleClass: PropTypes.string,
    btnClass: PropTypes.string
};

export default TextBox;