import React from 'react';
import PropTypes from 'prop-types';
// import SanitizedHTML from 'react-sanitized-html';
import ReactMarkdown from "react-markdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import  "./assets/_index.scss";

const IntroText = (props) => {
    return (
        <section className={`section section-introtext${props.sectionClass}`}>
            <Container>
                <Row>
                    <Col className={props.moduleClass}>
                        <ReactMarkdown source={props.Text} className="intro-text" />
                        {/* <SanitizedHTML
                            allowedTags={['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
                            'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
                            'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre']}
                            html={props.Text}
                            className="intro-text"
                        /> */}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

// Check all the prop types
IntroText.propTypes = {
    sectionClass: PropTypes.string,
    Text: PropTypes.string,
    moduleClass: PropTypes.string
};

// Specifies the default values for props:
IntroText.defaultProps = {
    sectionClass: ' pt-34 pb-33 pt-md-54 pb-md-52 pt-xl-93 pb-xl-78',
    Text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ut dignissim erat. Quisque in aliquet erat. Proin et laoreet sem. Donec sodales aliquam sapien.</p>',
    moduleClass: ''
};

export default IntroText;