import React from 'react';
import Img from "gatsby-image"
import { Link } from 'gatsby'

const OurPeopleCardImg = (props) => {
    return (
        <div className={props.imgClass}>
            {props.bioLink && props.imgNode && <Link to={props.bioLink}><Img fluid={props.imgNode.childImageSharp.fluid} /></Link>}
            {!props.bioLink && props.imgNode && <Img fluid={props.imgNode.childImageSharp.fluid} />}
        </div>
    );
}

export default OurPeopleCardImg;