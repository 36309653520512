
import React from 'react';
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import OurPeopleCard from "./OurPeopleCard";

import  "./assets/_index.scss";

const OurPeople = (props) => {
    return (
        <section className={`section section-our-people${props.sectionClass}`}>
            <Container>
                <Row>
                    <OurPeopleCard
                        peopleData={props.peopleData}
                        imgClass={props.imgClass}
                        itemClass={props.itemClass}
                        textItemClass={props.textItemClass}
                        titleClass={props.titleClass}
                        jobTitleClass={props.jobTitleClass}
                        listClass={props.listClass}
                    />
                </Row>
            </Container>
        </section>
    );
}

// Check all the prop types
OurPeople.propTypes = {
    sectionClass: PropTypes.string,
    itemClass: PropTypes.string,
    peopleData: PropTypes.array
};

// Specifies the default values for props:
OurPeople.defaultProps = {
    sectionClass: '',
    itemClass: 'our-people-card d-flex align-items-center mb-24 mb-md-60 mb-xl-84',
    imgClass: 'our-people-card__img ml-xl-auto order-xl-1',
    textItemClass: 'our-people-card__text order-xl-0 ml-20 ml-md-40 ml-xl-0',
    titleClass: 'our-people-card__title font-weight-bold mb-0 mb-md-9 mb-xl-1',
    jobTitleClass: 'our-people-card__job__title mb-5 mb-md-20',
    listClass: 'our-people-card__list list-inline mb-0',
    peopleData: [
        {
            id: 1,
            name: 'Iain White',
            image: '',
            jobTitle: 'Managing Director',
            phone: '+ 44 7891 219 274',
            videoLabel: 'Book a Video Call',
            videoUrl: '/',
            bioLabel: 'View Bio',
            bioLink: '/'
        },
        {
            id: 2,
            name: 'Myles White',
            image: '',
            jobTitle: 'Managing Director',
            phone: '+ 44 7891 219 274',
            videoLabel: 'Book a Video Call',
            videoUrl: '/',
            bioLabel: 'View Bio',
            bioLink: '/'
        },
        {
            id: 3,
            name: 'Iain White',
            image: '',
            jobTitle: 'Managing Director',
            phone: '+ 44 7891 219 274',
            videoLabel: 'Book a Video Call',
            videoUrl: '/',
            bioLabel: 'View Bio',
            bioLink: '/'
        },
        {
            id: 4,
            name: 'Myles White',
            image: '',
            jobTitle: 'Managing Director',
            phone: '+ 44 7891 219 274',
            videoLabel: 'Book a Video Call',
            videoUrl: '/',
            bioLabel: 'View Bio',
            bioLink: '/'
        },
        {
            id: 5,
            name: 'Iain White',
            image: '',
            jobTitle: 'Managing Director',
            phone: '+ 44 7891 219 274',
            videoLabel: 'Book a Video Call',
            videoUrl: '/',
            bioLabel: 'View Bio',
            bioLink: '/'
        },
        {
            id: 6,
            name: 'Myles White',
            image: '',
            jobTitle: 'Managing Director',
            phone: '+ 44 7891 219 274',
            videoLabel: 'Book a Video Call',
            videoUrl: '/',
            bioLabel: 'View Bio',
            bioLink: '/'
        }
    ]
};

export default OurPeople;