import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown";

import ContentHeaderImageBtns from "@components/modules/content-header-image/_content-header-image-btns";

const TextBox = (props) => {
    // console.log ("qweqewq", props.item.Text)

    return (
        <div className={`section-image-box-text__text ${props.boxClass}`}>
            <h2 className={props.titleClass}>{props.item.Title}</h2>
            <p>{props.item.Text}</p>

            <ContentHeaderImageBtns
                btnContainerClass=""
                btnClass={props.btnClass}
                ButtonData={props.item.Buttons}
            />
        </div>
    );
}

// Check all the prop types
TextBox.propTypes = {
    boxClass: PropTypes.string,
    titleClass: PropTypes.string,
    btnClass: PropTypes.string
};

export default TextBox;